import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { Box, Container } from '@material-ui/core'
import { Hero } from '../components'
import {
  DarkMode,
  JobForm,
  RichText,
  withGlobals,
} from '../components'

const Job = ({ contentfulArchive, contentfulJob }) => {
  return (
    <div>
      <Hero
        hero={{
          ...get(contentfulArchive, 'hero'),
          title: contentfulJob.title,
        }}
      />
      <Box mt={5}>
        <Container maxWidth="md">
          <RichText
            json={get(contentfulJob, 'body.json')}
          />
        </Container>
      </Box>
      <Box mt={5}>
        <DarkMode>
          <JobForm job={get(contentfulJob, 'title')} />
        </DarkMode>
      </Box>
    </div>
  )
}

Job.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentfulJob: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contentfulArchive: PropTypes.object.isRequired,
}

export const query = graphql`
  query($id: String!) {
    contentfulJob(id: { eq: $id }) {
      id
      title
      node_locale
      body {
        json
      }
    }

    contentfulArchive(
      contentful_id: { eq: "56tdvBGBFhDyF7Hd1xqdSQ" }
    ) {
      hero {
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`

export default withGlobals(Job)
